<template>
  <div
    id="page-print"
  >
    <b-navbar
      toggleable="lg"
      variant="white"
    >
      <b-navbar-brand href="#">
        <a
          href="/"
        >
          <logo-icon
            id="LogoIcon"
            class="logo"
          />
        </a>
      </b-navbar-brand>

      <b-navbar-nav class="ml-auto">

        <div>
          <b-button
            class="my-2 my-sm-0"
            variant="flat-primary"
            @click="print()"
          >
            <feather-icon
              icon="PrinterIcon"
              size="24"
            />
          </b-button>

          <b-button
            class="my-2 my-sm-0"
            variant="flat-secondary"
            @click="$router.back()"
          >
            <feather-icon
              icon="XIcon"
              size="24"
            />
          </b-button>
        </div>

      </b-navbar-nav>

    </b-navbar>

    <router-view />

  </div>
</template>

<script>

import {
  BButton, BNavbar, BNavbarBrand, BNavbarNav,
} from 'bootstrap-vue'
import LogoIcon from '@core-override/layouts/components/LogoIcon.vue'

export default {
  components: {
    LogoIcon,
    BNavbar,
    BNavbarBrand,
    BNavbarNav,
    BButton,
  },
  methods: {
    print() {
      window.print()
    },
  },
}
</script>

<style lang="scss">
small {
  font-size: 8pt;
}

.logo {
  margin-top: 5px;
  height: 48px;
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  html, body {
    width: 210mm;
    height: 297mm;
  }
}
</style>
